<template>
  <div class="home">
    <h1>Direct Message</h1>
  </div>
</template>

<script>
export default {
  name: 'Explore'
}
</script>
