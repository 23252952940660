<template>
  <div class="home">
    <CustomText tag="h1">Profile Tag</CustomText>
  </div>
</template>

<script>
import CustomText from '@/components/CustomText'

export default {
  name: 'ProfileTag',
  components: {
    CustomText
  }
}
</script>

<style scoped></style>
